@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import './assets/scss/sb-admin-2.scss';

hr.horizontal-dark {
    background-color: transparent;
    background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, .4), transparent);
    -webkit-font-smoothing: antialiased;
    margin: 1rem 0;
    color: inherit;
    border: 0;
    height: 1px;
    opacity: .25;
}

.form-label,
label {
    font-size: .75rem;
    font-weight: 700;
    color: #344767;
    margin-left: 0.25rem;
}

.form-group>p {
    margin-left: 0.25rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057;
}

.form-control:not(.form-control-user) {
    padding: 0.5rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057;
    border: 1px solid #d2d6da;
    appearance: none;
    border-radius: 0.5rem;
    transition: box-shadow .15s ease, border-color .15s ease;
    height: initial;
}